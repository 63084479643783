<template>
  <div class="all">
    <Navbar class="tablet-hidden mobile-hidden" />
    <div class="page">
      <Header />
      <div class="home container mt-1">
        <div v-show="loading" class="flex-center mt-3">
          <div class="loading2"></div>
        </div>

        <div class="videos-holder pb-2">
          <div v-for="tip in tips" :key="tip.id">
            <div
              class="title mt-1-desktop mb-1-desktop mb-1-mobile mb-1-tablet animated fadeIn"
            >
              {{ tip.title }}
            </div>
            <iframe
              :src="`https://www.youtube.com/embed/${tip.video}`"
              :title="tip.title"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>

        <div v-show="!loading && tips.length < 1">
          <span class="warning">
            {{ t("Não há apoio extra referente a esta data.") }}
          </span>
        </div>

        <div
          v-show="!loading && !allDataFetched && tips.length > 0"
          class="btn desktop4 tablet5"
        >
          <span>{{ t("Ver mais") }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import http from "@/http";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import authentication from "../util/authentication";
export default {
  components: {
    Navbar,
    Header,
  },
  data() {
    return {
      tips: [],
      loading: true,
      page: 1,
      take: 15,
      allDataFetched: false,
    };
  },
  created() {
    http
      .get(
        `/tip?isDailyTip=false&page=${this.page}&take=${this.take}&orderBy=timestamp`,
        authentication.getHeaders()
      )
      .then((response) => {
        this.tips = [...this.tips, ...response.data.list];
        if (this.page < response.data.pages) this.page++;
        else this.allDataFetched = true;
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.loading = false;
      });
  },
};
</script>
<style lang="scss" scoped>
.home.container {
  width: 90%;
  max-width: initial;
}

.title {
  color: var(--secondary) !important;
}

.videos-holder {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 1.5em;
  iframe {
    position: relative;
    width: 100%;
    max-width: 600px;
    aspect-ratio: 16/9;
  }
  .title {
    font-size: 1.5em;
  }
}
</style>
